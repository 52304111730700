<template>
  <div>
    <a-form-model
      ref="newBalanceOperationForm"
      :model="formOperation"
      :rules="formOperationRules"
    >
      <a-row>
        <a-col :span="8" class="pr-1">
          <a-form-model-item ref="amount" :label="$t('common.sum')" prop="amount" class="pb-0 mb-0">
            <a-input-number v-model="formOperation.amount" :min="0" :max="99999" :step="0.01" @change="operationAmountChange" style="width: 100%" />
          </a-form-model-item>
          <div class="mt-1">
            <div>
              <a-button type="button" :loading="loading.withdraw" icon="arrow-down" @click="onSubmit('withdraw')" class="width-100p text-danger">
                {{ $t('buttons.btnWithdraw') }}
              </a-button>
            </div>
            <div class="mt-2">
              <a-button type="button" :loading="loading.deposit" icon="arrow-up" @click="onSubmit('deposit')" class="width-100p text-success">
                {{ $t('buttons.btnDeposit') }}
              </a-button>
            </div>
          </div>
        </a-col>
        <a-col :span="16" class="pl-1">
          <a-form-model-item ref="comment" :label="$t('common.comment')" prop="comment">
<!--            <a-input v-model="formOperation.comment" />-->
            <a-textarea
              v-model="formOperation.comment"
              :placeholder="$t('placeholders.commentForOperation')"
              :auto-size="{ minRows: 5, maxRows: 5 }"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import ClientService from '../../../services/api/client/client.service'

export default {
  name: 'newClientBalanceOperationModal',
  props: ['clientId'],
  data() {
    return {
      loading: { deposit: false, withdraw: false },
      formOperation: {
        type: 'deposit',
        amount: 0,
        comment: '',
      },
      formOperationRules: {
        type: [{ required: true, message: 'Тип операции обязателен', trigger: 'change' }],
        amount: [
          { required: true, message: 'Сумма обязательна', trigger: 'change' },
        ],
        comment: [{ max: 150, message: 'Максимальная длина 150 символов', trigger: 'change' }],
      },
    }
  },
  methods: {
    async onSubmit(type) {
      this.$refs.newBalanceOperationForm.validate(valid => {
        if (valid) {
          return this.createBalanceOperation(type)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async createBalanceOperation(type) {
      // --
      this.formOperation.type = type
      this.loading[type] = true
      return ClientService.createClientBalanceOperation(this.clientId, this.formOperation).then((response) => {
        this.loading[type] = false
        this.$message.success('Balance operation succeeded')
        this.operationCreated(response)
      }).catch(error => {
        this.loading[type] = false
        console.log(error)
        this.$message.error('Balance operation not succeeded')
      })
    },
    operationCreated(operation) {
      this.$emit('submitModalFunction', operation)
    },
    operationAmountChange() {
      // ---
    },
  },
}
</script>

<style scoped>

</style>
