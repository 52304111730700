import apiClient from '../../axios'

export default class ClientService {
  static async getClients(reqData) {
    try {
      const response = await apiClient.get('/admin/clients', { params: reqData })
      return response
    } catch (error) {
      console.error('Error fetching clients:', error)
      throw error
    }
  }

  static async banClient(clientId, banData) {
    try {
      const response = await apiClient.patch(`/admin/clients/${clientId}/ban`, banData)
      return response.data.data
    } catch (error) {
      console.error('Error banning client:', error)
      throw error
    }
  }

  static async unBanClient(clientId) {
    try {
      const response = await apiClient.patch(`/admin/clients/${clientId}/unban`)
      return response.data.data
    } catch (error) {
      console.error('Error unbanning client:', error)
      throw error
    }
  }

  static async setClientVerified(clientId, state) {
    try {
      const response = await apiClient.patch(`/admin/clients/${clientId}/verified/${state}`)
      return response.data.data
    } catch (error) {
      console.error('Error setting client verification:', error)
      throw error
    }
  }

  static async getClientBalance(clientId) {
    try {
      const response = await apiClient.get(`/admin/clients/${clientId}/balance`)
      return response.data.data
    } catch (error) {
      console.error('Error fetching client balance:', error)
      throw error
    }
  }

  static async getClientBalanceOperations(clientId, reqData) {
    try {
      const response = await apiClient.get(`/admin/clients/${clientId}/balance/operations`, { params: reqData })
      return response
    } catch (error) {
      console.error('Error fetching client balance operations:', error)
      throw error
    }
  }

  static async createClientBalanceOperation(clientId, reqData) {
    const data = { type: reqData.type, amount: reqData.amount, comment: reqData.comment }
    try {
      const response = await apiClient.post(`/admin/clients/${clientId}/balance/operations`, data)
      return response.data.data
    } catch (error) {
      console.error('Error creating client balance operation:', error)
      throw error
    }
  }

  static async unbindPhoneNumber(clientId) {
    try {
      const response = await apiClient.patch(`/admin/clients/${clientId}/unbind_phone`)
      return response.data.data
    } catch (error) {
      console.error('Error unbinding phone number:', error)
      throw error
    }
  }
}
